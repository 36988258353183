@include media-breakpoint-down(md) {
  .sidenav {
    display: none;
  }
} 

.sidenav {
  position: fixed;
  width: $nav-width;
  z-index: 1000;
  bottom: 0px;
  left: 0;
  overflow-x: hidden;
  padding-top: 107px;
  border-right: 1px solid rgba(0, 0, 0, 0.125);

  ul {
    border-top: 1px solid rgba(0, 0, 0, 0.125);
  }

  .closebtn {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  a {
    color: $white;
  }
  
  .accordion-custome-hover {
    &:hover {
      background-color: $accordion-custome-hover-color !important;
      color: $primary;
      transition: all 1s ease;
    }
    a {
      display: block;
      &:hover {
          color: $primary;
          text-decoration: none;
        }
     }
  }
  li[aria-expanded="true"] {
    background-color: $accordion-custome-hover-color !important;
  }
 
}
.sidenav-open { 
  margin-left: 0;
}


/* Style page content - use this if you want to push the page content to the right when you open the side navigation */
#main {
  transition: margin-left .5s;
  padding: 20px;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  // .sidenav a {font-size: 18px;}
}
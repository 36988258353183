.marineria-nav {
  width: calc(100% - 250px) !important;
  margin-left: auto !important;

  @include media-breakpoint-down(md) {
    width: 100% !important;
  }
}

.nav-content {
  height: 65px;

  .menu-area {
    left: 1rem;
    top: 1rem;
  }
  .logo-area {
    max-width: 340px;
    margin: 0 auto;
  }

  .login-area {
    right: 1rem; 
    top: 1rem;
  }

}

.logo {
  // position: absolute;
  // top: -.5rem;
  // left: 20%;
  // max-width: 360px;
    // transition:left 1s linear;
  @include media-breakpoint-only(xs) { 
    position: relative;
    width: 100%;
   }
}

.logoTransitions {
  transition: top .2s;
  top: 0;
  transition-timing-function: ease;
}

.relativeLogo {
  position: relative;
  transition:.6s all;
}

.no-min-width {
  min-width: 0 !important;
}

.h-100 {
  height: 100% !important;
}

.w-15 {
  width: 15% !important;
  max-width: 15% !important;
}

.custome-col {
  width: 100% !important;
}

.list-style-none {
  list-style: none !important;
}

.mh-default {
  max-height: $default-max-height;
}

.custome-border {
  &::after {
    content: "";
    display: block;
    width: 100%;
    border: 1px solid blue;
}
}

@include media-breakpoint-up(sm) {
  .custome-col {
    width: 50% !important;
  }
}

@include media-breakpoint-up(md) {
  .custome-col {
    width: 33.3% !important;
  }
}

@include media-breakpoint-up(lg) {
  .custome-col {
    width: 25% !important;
  }
}

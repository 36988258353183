@import './variable.scss';
// @import './uti.scss';

$enable-gradients: true;
$enable-responsive-font-sizes: true;
html {
  font-size: $document-font-size;
}

$theme-colors: (
  "primary": #ff6633,
  "dark": #3b4151,
  "grey": #949fb5,
  "black": #000000
);

@import "node_modules/bootstrap/scss/bootstrap";
@import './announcment.scss';
@import './footer.scss';
@import './header.scss';
@import './side-nav.scss';
@import './uti.scss';
@import './home.scss';

.content {
  min-height: calc(100vh - 280px);
  @include media-breakpoint-up(lg) {
    margin-left: $nav-width;
    margin-top: 2rem;
  }
} 

.customeTrans {
  transition:.6s all;
}

a:hover {
  text-decoration: none !important;
}

@include media-breakpoint-up(xl) { 
  .container {
    max-width: 960px !important;
  }
 }

 .dropdown-item {
   padding: 1rem 1.5rem;
 }


 .gsc-control-cse {
  border: none !important;
  padding: 0 !important;
}

form.gsc-search-box {
  margin: 0 !important;
}

table.gsc-search-box {
  margin: 0 !important;
  border: none !important;
}

table.gsc-search-box td.gsc-input {
  padding-right: 0 !important;
}

input.gsc-input,
.gsc-input-box,
.gsc-input-box-hover,
.gsc-input-box-focus {
  border-color: #ff6633 !important;
}

.gsc-input-box {
  padding-top: 1px !important;
  padding-bottom: 0 !important;
}

.gsc-input {
  font-size: 1rem !important;
}

.gsc-search-button.gsc-search-button-v2 {
  padding: 5px;
  border: none;
  background: transparent;
}

.gsib_a {
  padding: 3px !important;
}

.gsc-search-button.gsc-search-button-v2 svg {
  fill: #ff6633;
}

.gsc-input-box {
  border: none !important;
}
